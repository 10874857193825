<template>
    <div class="container">
        <!-- BOOK -->
        <div class="book-img-cont" :class='$mq'>
            <KImage :src="require('@/assets/march/pr-book.png')" class="book-img" alt="" width="100%"/>
            <div class="book-light"></div>
        </div>

        <h1>Создай свою первую авторскую куклу за&nbsp;2&nbsp;месяца</h1>
        <p>Электронная книга для начинающих по&nbsp;созданию куклы с&nbsp;шарнирными соединениями. Всего 227 страниц, 1047 фотографий, отделяют Вас от&nbsp;создания своей невероятной куклы. </p>
        <div class="book-price">
             <p>2490&#8381;</p>
             <p class="old" :class='$mq'>3000&#8381;</p>
        </div>

        <KRoundedButton class='book-button' :class='$mq' text-color="#fff" color="#e03955" @click="openBook">
            Подробнее о&nbsp;книге
            <template v-slot:icon>
                <KIHandFlower icon-color="#FFF" height="50px" width="50px"/>
            </template>
        </KRoundedButton>

        <!-- COURSES -->
        <h2>Видеокурсы</h2>
        <div class="main-courses-flex" :class="$mq">
          <div class="main-courses-block" :class="$mq" @click="openBeginners">
            <div class="text-course">
              <p>Для начинающих</p>
              <h3>Будуарная<br> кукла</h3>
            </div>
            <div class="bodoir-course" :class="$mq">
              <KImage :src="require('@/assets/main/new/bodoit.png')" width="100%"/>
            </div>
            <KRoundedButton class='courses-button' :class='$mq' text-color="#fff" color="#d76666">
              Подробнее о&nbsp;курсе
              <template v-slot:icon>
                <KIHandFlower icon-color="#FFF" width="50px" height="50px"/>
              </template>
            </KRoundedButton>
          </div>

          <div class="main-courses-block" :class="$mq" @click="openBJD">
            <div class="text-course">
              <p>Для продвинутых</p>
              <h3>Шарнирная<br> кукла</h3>
            </div>
            <div class="bodoir-course bjd" :class="$mq">
              <KImage :src="require('@/assets/main/new/bjd.png')" width="100%"/>
            </div>
            <KRoundedButton class='courses-button' :class='$mq' text-color="#fff" color="#4f99a3">
              Подробнее о&nbsp;курсе
              <template v-slot:icon>
                <KIHandFlower icon-color="#FFF" width="50px" height="50px"/>
              </template>
            </KRoundedButton>
          </div>
        </div>

        <!-- OTHER -->
        <h2>Остальное</h2>
        <router-link to="/" class="button doll">Зайти на&nbsp;мой сайт
            <div class="tp-icon"></div>
        </router-link>

        <a href="https://www.youtube.com/channel/UC-0xHId8Riqcdbr8Yj67AXg" class="button youtube">Мой Youtube канал
            <div class="tp-icon yt"></div>
        </a>

        <a href="https://vk.com/strasti_sharnir" class="button vk">Моя группа Вконтакте
            <div class="tp-icon vk"></div>
        </a>
    </div>    
</template>

<script>
    import KImage from "@/components/Widgets/KImage";
    import KRoundedButton from "@/components/Widgets/KRoundedButton";
    import KIHandFlower from "@/components/Widgets/Icons/KIHandFlower";

    export default {
    name: "MarchBook",
    components: {
        KImage,
        KRoundedButton,
        KIHandFlower
    }, 
    methods: {
        openBook() {
        this.$router.push('/book')
        },
        openBJD() {
        this.$router.push('/bjd')
        },
        openBeginners() {
        this.$router.push('/beginners')
        }
    }
    }
</script>

<style scoped>
    .container {
        width: 100%;
        max-width: 500px;
        position: relative;
        padding: 0 2em;
        box-sizing: border-box;
    }

    h1 {
        font-family: "Montserrat", sans-serif;
        font-size: 1.6em;
        line-height: 1em;
        text-align: center;
        font-weight: 600;
        margin-bottom: 0.5em;
    }

    h2 {
        font-size: 3em;
        font-family: "Kudryashev", sans-serif;
        line-height: 1em;
        text-align: center;
        margin-top: 1em;
        position: relative;
        z-index: 10;
    }

    h3 {
        font-size: 2em;
        font-family: "Montserrat", sans-serif;
        line-height: 1em;
    }

    p {
        font-size: 0.95em;
        line-height: 1.3em;
        margin-bottom: 1em;
    }

    /* BOOK */
    .book-img-cont {
        position: relative;
        transform: rotate(5deg);
    }

    .book-light {
        width: 500px;
        height: 500px;
        background: radial-gradient(circle 250px, #d76666, transparent 100%);
        position: absolute;
        z-index: 0;
        top: -5em;
        left: 50%;
        transform: translateX(-50%);
    }

    .book-img {
        position: relative;
        z-index: 1;        
    }
    
    .book-price {
        display: flex;
        justify-content: space-between;
        font-size: 2.3em;
    }
        .book-price p {
            margin-bottom: 0.5em;
        }
        .book-price .old {
            color: #ffffff55;
            text-decoration: line-through;
        }
    
    .book-button, .courses-button {
        font-size: 1em;
        font-weight: 600;
    }

    /* COURSES */
    .main-courses-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -2em;
    }

    .main-courses-block {
        width: 90%;
        position: relative;
        cursor: pointer;
        margin-bottom: 0em;
    }
    .main-courses-block:hover .march-main-button {
        transform: scale(1.1);
    }

    .main-courses-block p {
    color: #ffffff99;
    }

    .bodoir-course {
        width: 320px;
        margin-bottom: -3em;
        position: relative;
        right: -5em;
        z-index: 0;
    }
    .bodoir-course.mobile.bjd {
        right: -2em;
    }

    .text-course {
        position: absolute;
        bottom: 8em;
        z-index: 1;
    }

    /* OTHER */
    .button {
        width: 100%;
        box-sizing: border-box;
        border: none;
        padding: 0.75em 2em;
        line-height: 1em;
        margin: 2em 0;
        border-radius: 50px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
    }

     .button:hover {
        transform: scale(1.05);
        color: white;
    }

    .button.doll {
        background-color: #d76666;
        box-shadow: #d76666 0px 0px 25px 0px;
    }

    .button.youtube {
        /* border: 2px solid #eb2222; */
        background-color: #eb2222;
        box-shadow: #eb2222 0px 0px 25px 0px;
    }

    .button.vk {
        /* border: 2px solid #436fa3; */
        background-color: #436fa3;
        box-shadow: #436fa3 0px 0px 25px 0px;
    }

    .tp-icon {
        background: url('~@/assets/taplink_icons.svg');
        width: 45px;
        height: 40px;
        background-size: 40px;
        position: relative;
        top: 50%;
        background-position-y: -20px;
        background-repeat: no-repeat;
        margin-left: 1em;
    }

    .tp-icon.yt {
        background-position-y: -350px;
    }
    .tp-icon.vk {
        background-position-y: -290px;
    }
</style>